Drupal.behaviors.siteHeaderFormatterV1 = (function ($) {
  var $html = $();
  var $body = $();
  var $template = $();
  var $gnavLogo = $();
  var $gnavOffers = $();
  var $searchIcons = $();
  var $mobileSearchInput = $();
  var $gnavHamburger = $();
  var $gnavMainSectionWrapper = $();
  var $gnavMobileSectionsMenu = $();
  var $gnavMainSection = $();
  var $gnavMainSectionContent = $();
  var $gnavMainSectionTrigger = $();
  var $mainSectionTriggerReset = $();
  var $triggerResetLabel = $();
  var $subSectionTriggerReset = $();
  var $allSectionGroups = $();
  var $allSectionTriggerResetLabels = $();
  var $allSectionGroupContent = $();
  var $gnavLinks = $();
  // Calculate desktop for mobile menu stuff.
  var bps = Unison.fetch.all();
  var bp = Unison.fetch.now();
  var isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
  var position = $(window).scrollTop();
  var offersHeight = 0;
  var hideHeaderOnScroll = false;
  var linksToNavigate =
    'a, button, input, select, textarea, iframe, object, embed, *[tabindex], *[contenteditable]';
  var tabbableElements =
    'a:tabbable, button:tabbable, input:tabbable, select:tabbable, textarea:tabbable, iframe:tabbable, object:tabbable, embed:tabbable, *[tabindex]:tabbable';
  var noTabbableElements = '[tabindex=-1], [disabled], [aria-hidden=true]';
  var timer;
  var $resetNavSections = $();
  var behavior = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      $html = $('html');
      $body = $('body');
      $template = $('.js-site-header-formatter-v1', context);
      $searchIcons = $('.js-esearch-open-icon', context);
      $mobileSearchInput = $('.js-site-header-formatter-search-icon-wrapper', context);
      $gnavLogo = $('.js-site-header-formatter__logo', $template);
      $gnavOffers = $('.site-header-formatter__offers', $template);
      $gnavHamburger = $('#gnav_mobile_trigger_input', $template);
      $gnavMainSectionWrapper = $('.js-site-header-formatter__sections', $template);
      $gnavMobileSectionsMenu = $(
        '.js-site-header-formatter__mobile-menu-header',
        $gnavMainSectionWrapper
      );
      $gnavMainSection = $('.js-header-nav-section__section-v1', $gnavMainSectionWrapper);
      $gnavMainSectionContent = $('.js-header-nav-section__section-content', $gnavMainSection);
      $gnavMainSectionTrigger = $('.js-header-nav-section__section-label', $gnavMainSection);
      $mainSectionTriggerReset = $('.js-site-header-formatter__trigger-reset', $template);
      $triggerResetLabel = $('.js-gnav-section-trigger-reset', $template);
      $subSectionTriggerReset = $('.site-header-formatter__subsection-trigger-reset', $template);
      // These elements are in gnav_section_formatter_v1, we collapse them too when gnav is closed.
      $allSectionGroups = $('.js-gnav-section-formatter-v1', $template);
      $allSectionTriggerResetLabels = $allSectionGroups.find(
        '.js-gnav-section-formatter__link--trigger'
      );
      $allSectionGroupContent = $('.js-gnav-section-formatter__content-wrapper', $allSectionGroups);
      // These elements are in gnav_links_v1.
      $gnavLinks = $gnavMainSectionWrapper.find('.js-gnav-links-trigger');
      hideHeaderOnScroll = $template.hasClass('hide-site-header-on-scroll');
      offersHeight = $gnavOffers.outerHeight(true);
      $resetNavSections = $(
        '.js-site-header-formatter__nav-reset-section, .js-header-offers-banner-formatter-carousel, .js-site-header-formatter__content-block-sitewide-banner',
        context
      );

      if ($.cookie('hide_header_offer_banner')) {
        $template.toggleClass('header-offers-banner-hidden', true);
      }

      function _updateNavStatus() {
        var scroll = $(window).scrollTop();

        // If the user scroll down, hide the Nav
        if (scroll <= position) {
          $body.toggleClass('site-header-formatter--hidden', false);
        } else if (scroll > 5) {
          $body.toggleClass('site-header-formatter--hidden', true);
          $body.toggleClass('site-header-formatter--sticky', true);
        }

        if (scroll <= offersHeight) {
          $body.toggleClass('site-header-formatter--sticky', false);
        }

        position = scroll;
      }

      // close the nav.
      function _closeGnav(keepActive) {
        $html.toggleClass('active-gnav', !!keepActive);
        $gnavMainSectionWrapper.attr('aria-expanded', !!keepActive || isDesktop);

        // Reset Header Nav Section elements state.
        $gnavMainSection.attr('aria-expanded', 'false');
        $gnavMainSectionContent.attr('aria-hidden', 'true');

        // Reset Section Formatter elements state.
        $allSectionGroups.attr('aria-expanded', 'false');
        $allSectionGroupContent.attr('aria-hidden', 'true');

        // Reset Links state.
        $gnavLinks.prop('checked', false).change();
        $gnavLinks.siblings('.gnav-links__content').attr('aria-expanded', isDesktop);

        $subSectionTriggerReset.prop('checked', true);

        if (!keepActive) {
          $mainSectionTriggerReset.prop('checked', true);

          if (!isDesktop) {
            $gnavHamburger.prop('checked', false);
          }
        }
      }

      function _setTitle() {
        var page_title = document.title;

        if ($gnavLogo.length) {
          $gnavLogo.before('<h1 class="page-title hidden">' + page_title + '</h1>');
        }
      }

      function _gnavHeaderTrigger() {
        // Calculate desktop for mobile menu stuff.
        if (isDesktop) {
          $gnavMainSectionTrigger
            .off('mouseover.siteHeader click.siteHeader touchstart.siteHeader')
            .on('click.siteHeader touchstart.siteHeader', function (e) {
              var $self = $(this);

              _gnavPcTrigger($self, e);
            })
            .on('mouseover.siteHeader', function () {
              $(this).trigger('click');
            });
        } else {
          $gnavMainSectionTrigger
            .off('mouseover.siteHeader click.siteHeader touchstart.siteHeader')
            .on('click.siteHeader', function (e) {
              var $self = $(this);

              _gnavMobileTrigger($self, e);
            });
        }
      }

      function _gnavPcTrigger($self, e) {
        var $parent = $self.parents('.js-header-nav-section__section-v1');
        var $input = $parent.prev();
        var $content = $parent.find('.js-header-nav-section__section-content');
        var $subNav = $content.find('.js-gnav-section-formatter-v1').first();
        var $subNavcontent = $subNav.find('.js-gnav-section-formatter__content-wrapper');
        var keepActive = $content.length ? true : false;

        if ($input.prop('checked') === true) {
          return;
        }
        _closeGnav(keepActive);
        if ($content.length) {
          e.preventDefault();
          $input.prop('checked', true);
          $html.toggleClass('active-utility-overlay', false);
          $html.toggleClass('active-gnav', true);
          $parent.attr('aria-expanded', 'true');
          $content.attr('aria-hidden', 'false');
          $subNav.attr('aria-expanded', 'true');
          $subNavcontent.attr('aria-hidden', 'false');
        }
      }

      function _gnavMobileTrigger($self, e) {
        var $parent = $self.parents('.js-header-nav-section__section-v1');
        var $input = $parent.prev();
        var $content = $parent.find('.js-header-nav-section__section-content');
        var $subNav = $parent.find('.js-gnav-section-formatter-v1');

        _closeGnav(true);
        if (!$content.length) {
          return;
        }
        e.preventDefault();
        $input.prop('checked', true);
        $html.toggleClass('active-utility-overlay', false);
        $html.toggleClass('active-gnav', true);
        $gnavMainSectionWrapper.attr('aria-expanded', 'true');
        $subNav.attr('aria-expanded', 'true');
        $parent.attr('aria-expanded', 'true');
        $content.attr('aria-hidden', 'false');
      }

      function _toggleMobileMenu() {
        // If mobile content is open then reset radio buttom for PC.
        $mainSectionTriggerReset.prop('checked', true);
        if ($gnavMainSectionWrapper.attr('aria-expanded') === 'false') {
          $html.toggleClass('active-gnav', true);
          $gnavMainSectionWrapper.attr('aria-expanded', 'true');
        } else {
          _closeGnav();
        }
      }

      // Subnav updates using js only.
      if (isDesktop) {
        $gnavMainSectionWrapper.attr('aria-expanded', 'true');
        $gnavLinks.siblings('.gnav-links__content').attr('aria-expanded', 'true');
      }

      if (hideHeaderOnScroll) {
        $(window)
          .off('scroll.gnavHeaderBlock')
          .on('scroll.gnavHeaderBlock', _.throttle(_updateNavStatus, 300));
        _updateNavStatus();
      }

      _setTitle();
      _gnavHeaderTrigger();

      Unison.on('change', function () {
        bp = Unison.fetch.now();
        offersHeight = $gnavOffers.outerHeight(true);
        isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
        _closeGnav();
        _gnavHeaderTrigger();
      });

      $triggerResetLabel
        .off('mouseover.siteHeader click.siteHeader')
        .on('click.siteHeader', function () {
          if (isDesktop) {
            _closeGnav();
          }
        })
        .on('mouseover.siteHeader', function () {
          if ($(this).hasClass('site-header-formatter-overlay')) {
            $(document).trigger('check_reset_gnav_radio_buttom');
          }
        });

      // Mobile only icon takes user back to main gnav sections display and collapses all open sections
      $gnavMobileSectionsMenu.once().on('click', function () {
        _closeGnav(true);
      });

      $gnavLinks.once().on('click', function () {
        var expand = $(this).is(':checked');

        $(this).siblings('.gnav-links__content').attr('aria-expanded', expand);
      });

      $gnavHamburger.once().on('click', _toggleMobileMenu);

      $mainSectionTriggerReset.once().on('click', function () {
        if (isDesktop) {
          _closeGnav();
        }
      });

      $allSectionTriggerResetLabels.once().on('mouseover.siteHeader', function () {
        if (isDesktop) {
          $(this).trigger('click');
        }
      });

      // Close Nav dropdown when moving the mouse out or clicking outside of it:
      $html.once().on(
        'click mouseout mouseover',
        _.debounce(function (event) {
          if (
            !!isDesktop &&
            $html.hasClass('active-gnav') &&
            ((event.type === 'mouseout' && !event.relatedTarget) ||
              !$(event.target).parents('.site-header-formatter__header').length)
          ) {
            _closeGnav();
          }
        }, 100)
      );

      $resetNavSections.on('mouseover', function () {
        _closeGnav();
      });

      $(document).on('hide_header_offer_banner', function () {
        $template.toggleClass('header-offers-banner-hidden', true);
      });

      $(document).on('check_reset_gnav_radio_buttom', function () {
        _closeGnav();
        $mainSectionTriggerReset.prop('checked', true);
      });

      $mobileSearchInput.once().on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $gnavHamburger.prop('checked', false);
        _toggleMobileMenu();
        $searchIcons.first().focus().trigger('click');
      });

      $(linksToNavigate, $template)
        .not(noTabbableElements)
        .on('keydown', function (event) {
          var $this = $(this);
          var key = event.key;
          var $tabbableElements = $(tabbableElements, $template).not(noTabbableElements);
          var index = $tabbableElements.index($this);
          var target;

          $html.addClass('ada-enabled');
          switch (key) {
            case 'ArrowLeft':
              target = index === 0 ? -1 : index - 1;
              break;
            case 'ArrowUp':
              target = index === 0 ? -1 : index - 1;
              break;
            case 'ArrowRight':
              target = index === $tabbableElements.length - 1 ? 0 : index + 1;
              break;
            case 'ArrowDown':
              target = index === $tabbableElements.length - 1 ? 0 : index + 1;
              break;
            case 'Enter':
              if (event.target) {
                if (
                  event.target.localName === 'a' &&
                  $(event.target).attr('aria-haspopup') === 'true'
                ) {
                  event.preventDefault();
                }
                $(event.target).trigger('click');
              }

              if (timer) {
                clearTimeout(timer);
              }
              // If click on close icon then focus the previous element.
              if ($this.attr('for') === 'gnav_section_trigger_reset') {
                focusTimer(index - 1);
              }
              // If the link/label has pop up then click to open
              if ($this[0].hasAttribute('aria-haspopup')) {
                // Focus next element after a time in case it takes time to load
                focusTimer(index + 1);
              }
              break;
            case 'Escape':
              $(document).trigger('check_reset_gnav_radio_buttom');
              if (timer) {
                clearTimeout(timer);
              }
              focusTimer(index - 1);
              break;
          }

          if (target !== null && target !== undefined) {
            focusElement(target);
          }
        });

      function focusTimer(index) {
        timer = setTimeout(function () {
          focusElement(index);
        }, 250);
      }

      function focusElement(index) {
        $(tabbableElements, $template).not(noTabbableElements).eq(index, 10).focus();
      }

      $(document).on(
        'mousemove',
        _.debounce(function () {
          // If mouse movement is detected then remove ada class.
          if ($html.hasClass('ada-enabled')) {
            $html.removeClass('ada-enabled');
          }
        }, 200)
      );
    }
  };

  return behavior;
})(jQuery);
